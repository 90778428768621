import React from 'react';

import './contacts.scss';
import { GOOGLE_MAP_POINT } from "../constants/urls";
import GMap from "../common/g_map";

export default function Contacts() {
  return (
    <div className="t-contacts">
      <div className="t-contacts__id" id='contacts'/>
      <div className="t-contacts__info">
        <a className="t-contacts__phone" href="tel:+7 (495) 165-19-25">+7 (495) 165-19-25</a>
        <a href="mailto:hi@basis.center" className="t-hibasiscenter">hi@basis.center</a>
        <div className="t-contacts__address">Москва-Сити, Башня «Империя», Пресненская набережная 6c2</div>
        <a className='--flex' href={GOOGLE_MAP_POINT} target="_blank" rel='noopener noreferrer'>
          <div className="t-contacts__how"/>
        </a>
        <div className="t-contacts__accreditation">ООО&nbsp;«ЦФИ Базис» осуществляет деятельность в&nbsp;сфере информационных технологий. Согласно Приказу Минцифры от&nbsp;08.10.22 следующие виды деятельности (код): 1.01;&nbsp;2.01</div>
      </div>

      <div className="t-contacts-map">
        <div className="t-contacts-map__container">
          <GMap desktop={false}/>
        </div>
      </div>
    </div>
  );
}
